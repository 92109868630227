<template>
  <div
    class="ocean-account-list padding-20 border-radius-6 box-shadow-light-grey"
  >
    <div class="ocean-account-list-container">
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        height="72vh"
        :isUseCheckbox="true"
        :loading="loading"
        @page-change="handlePageChange"
        @selectionChange="selectionChange"
      >
        <template #filter>
          <el-button @click="initData" style="margin-right: 10px"
            >刷新数据</el-button
          >
          <c-input
            v-model="search"
            placeholder="请输入账户名或备注"
            style="width: 300px; margin-right: 10px"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </c-input>
          <el-button type="primary" @click="handleAddAccount"
            >新增账号</el-button
          >
        </template>
        <template #operation="{ row }">
          <el-button class="button-small" @click="JumpTargetSystem(row)"
            >进入系统</el-button
          >
          <el-button
            class="button-small"
            type="warning"
            @click="handleBindUser(row)"
            >绑定人员</el-button
          >
          <el-button
            type="success"
            size="small"
            class="button-small"
            @click="handleEditItem(row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            class="button-small"
            size="small"
            @click="handleDelAccount(row.id)"
            >删除</el-button
          >
        </template>
      </dy-table>
    </div>
    <el-dialog
      append-to-body
      width="500px"
      :visible.sync="showBindUserDialog"
      :close-on-click-modal="false"
      @close="cancelBindUser"
    >
      <div slot="title">
        <span>绑定人员</span>
        <span style="margin-left: 5px; font-size: 14px; color: #ccc"
          >(支持批量粘贴)</span
        >
      </div>
      <div class="bind-user">
        <el-select
          v-model="userIds"
          style="width: 100%"
          filterable
          clearable
          multiple
          ref="inputBindUser"
          @focus="handleFocus"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div slot="footer">
        <el-button @click="cancelBindUser">取消</el-button>
        <el-button type="success" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      width="500px"
      :visible.sync="showAddDialog"
      :title="`${isEdit ? '编辑' : '新增'}账号`"
      :close-on-click-modal="false"
      @close="handleCancel"
    >
      <div class="custom-account-container">
        <div class="part">
          <span class="required">用户名：</span>
          <c-input v-model="form.userName" placeholder="请输入用户名"></c-input>
        </div>
        <div class="part">
          <span class="required">密码：</span>
          <c-input
            v-model="form.password"
            placeholder="请输入密码"
            type="password"
          ></c-input>
        </div>

        <div class="part">
          <span style="align-self: flex-start; margin-top: 10px">备注：</span>
          <c-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入备注"
          ></c-input>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleOk">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOeAccount,
  editOeAccount,
  delOeAccount,
  addOeAccount,
  bindOeAccountUser,
  getBindOeAccountUser,
} from "@/api/account";
// import { getUserList } from "@/api/user";
import { mapGetters } from "vuex";
import { isInstallChromeExtension, isValidArray } from "@/assets/js/utils";
export default {
  data() {
    return {
      page: 1,
      total: 0,
      search: "",
      section: [],
      rowList: [
        {
          label: "Id",
          prop: "id",
        },
        {
          label: "账号名",
          prop: "userName",
        },
        {
          label: "备注",
          prop: "remark",
          popover: true,
        },
        {
          label: "创建时间",
          prop: "createdTime",
        },
        {
          label: "操作",
          action: "operation",
          colWidth: "300px",
        },
      ],
      pageSize: 15,
      dataList: [],
      loading: false,
      curInfo: {},
      isEdit: false,
      repeatPassword: "",
      showAddDialog: false,
      showBindUserDialog: false,

      userIds: [],
      form: {},
      extendId: process.env.VUE_APP_BASE_extendId, // 插件id
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    showBindUserDialog: {
      handler(newV) {
        if (!newV) {
          this.$refs.inputBindUser.$refs.input.onpaste = null;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["userList"]),
  },
  methods: {
    initData() {
      this.loading = true;
      try {
        Promise.all([getOeAccount({ search: this.search })]).then((res) => {
          this.dataList = res[0];
        });
      } catch (e) {
        this.$message.error("获取数据失败");
      } finally {
        this.loading = false;
      }
    },
    handlePageChange(page) {
      this.page = page;
      this.initData();
    },
    handleCancel() {
      this.curInfo = {};
      this.form = {};
      this.isEdit = false;
      this.repeatPassword = "";
      this.showAddDialog = false;
    },
    handleOk() {
      if (!this.form.userName) return this.$message.warning("请输入用户名!");
      if (!this.form.password) return this.$message.warning("请输入密码!");
      if (this.isEdit === false) {
        addOeAccount({
          ...this.form,
        }).then(() => {
          this.$message.success("新增成功!");
          this.handleCancel();
          this.initData();
        });
      } else {
        editOeAccount(this.curInfo.id, {
          ...this.form,
        }).then(() => {
          this.$message.success("修改成功!");
          this.handleCancel();
          this.initData();
        });
      }
    },
    selectionChange(select) {
      this.selection = select;
    },
    handleAddAccount() {
      this.curInfo = {};
      this.isEdit = false;
      this.showAddDialog = true;
    },
    handleEditItem(row) {
      const { userName, remark } = row;
      this.form = { ...this.form, userName, remark };
      this.curInfo = row;
      this.isEdit = true;
      this.showAddDialog = true;
    },
    handleDelAccount(id) {
      this.$confirm("确定要删除吗？删除的数据将不可恢复!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delOeAccount(id).then(() => {
            this.$message.success("删除成功!");
            this.initData();
          });
        })
        .catch(() => {});
    },
    JumpTargetSystem(row) {
      if (!isInstallChromeExtension()) return;
      chrome.runtime.sendMessage(
        this.extendId,
        {
          novelapi: "clearCookie",
          url: "https://business.oceanengine.com/",
        },
        (data) => {
          try {
            data = JSON.parse(data);
            if (data.code === 200) {
              // ! 将该账号的数据传递到插件的localStorage进行存储
              chrome.runtime.sendMessage(
                this.extendId,
                {
                  OceanType: "LoginAccountInfo",
                  type: "set",
                  value: row,
                },
                () => {
                  window.open(
                    "https://business.oceanengine.com/site/login",
                    "_blank"
                  );
                }
              );
            } else {
              this.$message.error("清除cookie失败!");
            }
          } catch (e) {
            console.log(e);
            this.$message.error("请安装浏览器插件!");
          }
        }
      );
    },
    handleBindUser(row) {
      this.curInfo = row;
      getBindOeAccountUser({ id: row.id }).then((res) => {
        this.userIds = res.map((item) => item.adminUserId);
      });
      this.showBindUserDialog = true;
    },
    handleConfirm() {
      if (!isValidArray(this.userIds))
        return this.$message.error("请选择人员!");
      bindOeAccountUser({
        id: this.curInfo.id,
        adminUserIds: this.userIds,
      }).then(() => {
        this.$message.success("绑定成功!");
        this.cancelBindUser();
        this.initData();
      });
    },
    handleFocus() {
      this.$refs.inputBindUser.$refs.input.onpaste = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData("Text");
        pastedData = pastedData.split("\n").map((item) => {
          item = item.replace(/\r/g, "").trim();
          return item;
        });
        const arr = [];
        pastedData.forEach((item) => {
          if (item.split(" ").length > 1) {
            arr.push(...item.split(" ").filter(Boolean));
          }
        });
        pastedData = [...new Set([...pastedData, ...arr])];
        const validData = this.userList.filter((user) =>
          pastedData.find((item) => item === user.name)
        );
        validData.forEach((item) => {
          if (!this.userIds.includes(item.id)) {
            this.userIds.push(item.id);
          }
        });
      };
    },
    cancelBindUser() {
      this.curInfo = {};
      this.userIds = [];
      this.showBindUserDialog = false;
    },
  },
  components: {},
};
</script>
<style lang='scss' scoped>
.ocean-account-list {
  background: #fff;

  &-container {
  }
}
.custom-account-container {
  .part {
    @include flex(flex-start, center);
    margin-bottom: 15px;
    > span {
      width: 100px;
      text-align: right;
      flex-shrink: 0;
    }
  }
}
</style>
